
import React from 'react';
import Grid from '@material-ui/core/Grid'
// import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
// import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';


const Results = (props) => {

    const { history, answers, finished, strikes } = props;
    const noHint = answers.answers
    const zeroStrike = []
    const oneStrike = []
    const twoStrike = []


    //this goes through and sets the hints
    for (var i = 0; i < noHint.length; i++){
        let vowelsonly = ''
        let underline = ''
        let countryNumber = ''
        countryNumber = 'Country Number: ' + (i+1)
        vowelsonly = noHint[i].name.replace(/\s/g, '@');
        vowelsonly = vowelsonly.replace(/[bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ]/g, ' _ ')
        vowelsonly = vowelsonly.replace(/@/g, '\xa0\xa0\xa0\xa0');
        
        underline = vowelsonly.replace(/[aeiouAEIOU]/g, "_ ");
        zeroStrike.push(countryNumber)
        oneStrike.push(underline)
        twoStrike.push(vowelsonly)
    };


    if (finished === 'Won' || finished === 'Lost'){
        for (var j = 0; j < noHint.length; j++){
            history.push(noHint[j].name)
        }
    }
    return (               

        <Grid
            style={{
            width: "100%",
            backgroundColor: "#0f172a",
            }}
        >

            <List 
            style={{width: "100%", marginTop: "15px"}}>

            {noHint.map((ans, index) => {   
                return history.includes(ans.name) ? ( 
                    <Grid 
                    style={{
                        
                        marginTop: "5px",
                        height: "30px",
                        backgroundColor: "#6ca965",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <Typography style={{  color: "#FFFFFF" }}>
                            <li >{ans.name}</li> 
                        </Typography>
                    </Grid>
                ) :(
                    <Grid 
                    style={{
                        
                        marginTop: "5px",
                        height: "30px",
                        backgroundColor: "#475569",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        
                        <Typography style={{ display: "flex", color: "#fafafa" }} key={index}>
                            {strikes.length === 0 ? <li key={index}>{zeroStrike[index]}</li>
                            : null}
                            { strikes.length === 1  ? <li key={index}>{oneStrike[index]}</li>
                            : null}
                            { strikes.length === 2  ? <li  key={index}>{twoStrike[index]}</li> 
                            : null                          
                            }
                            
                        </Typography>
                    </Grid>
                    )
                    })}
                    
            </List>
        </Grid>  
    );
};

export default Results