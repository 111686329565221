import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Grid} from '@material-ui/core';


const Riddle = (props) => {

    const {finished, answers} = props;
    let finishedColor = "#475569"
    let headerText = answers.question;
    const redColor = "#FF0000"
    const greenColor = "#6ca965"
    if (finished === 'Lost'){
        try{

            finishedColor = redColor
            headerText = 'You Lost! 👎'
        } catch (error) {
            console.log(error.message) }
        
    }
    if (finished === 'Won'){
        try{

        finishedColor = greenColor
        headerText = 'Winner! 🎉'
    } catch (error) {
        console.log(error.message) }
    
    }


    return (
    <React.Fragment>
        <Grid id = 'headerTextBox'  style = {{border: 'solid', borderColor: "#fafafa", backgroundColor: finishedColor, marginTop: "3%"}}>
            <Typography id = 'headerTextSpan' variant="h5" style={{ color: "#fafafa", padding : "4%" }}>
                <span >{headerText}<br></br></span> 
            </Typography>
        </Grid>  
    </React.Fragment>
    );
}

export default Riddle
