import React from 'react';

function CountriesLoading(Component) {
	
	return function CountriesLoadingComponent({ isLoading, ...props }) {
		if (!isLoading) return <Component {...props} />;
		return (
			<p style={{ fontSize: '25px', marginBottom: '100px', color: 'white' }}>Fetching GeoRiddle...</p>
		);
	};
}
export default CountriesLoading;