import React from 'react';
// import Typography from '@material-ui/core/Typography';
// import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

// function Copyright() {
//     return (
// 		<Grid  align="center" style={{color:"white"}} >
// 			<Typography variant="footer" color="inherit" align="center">
// 			{'Copyright © '}
// 				GeoRiddle 
// 			{' '}
// 			2023
// 			{' '}
// 			</Typography>
// 			<Typography variant="footer" color="inherit" align="center">
// 			<br></br>georiddleapp@gmail.com
// 			</Typography>
// 		</Grid>
//     )
// }
// const footers = [
// 	{
// 		title: 'Info',
// 		description: ['Team', 'Contact us'],
// 	},
// 	{
// 		title: 'Legal',
// 		description: ['Privacy policy', 'Terms of use'],
// 	},
// ];

function Footer() {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Container maxWidth="md" component="footer" className={classes.footer}>
				{/* <Grid container spacing={4} justifyContent="space-evenly">

					{footers.map((footer) => (
						<Grid item xs={6} sm={3} key={footer.title}>
							<Typography variant="h6" color="textPrimary" gutterBottom>
								{footer.title}
							</Typography>
							<ul>
								{footer.description.map((item) => (
									<li key={item}>
										<Link href="#" variant="subtitle1" color="textSecondary">
											{item}
										</Link>
									</li>
								))}
							</ul>
						</Grid>
					))}
				</Grid>
				<Box mt={5}>
					<Copyright />
				</Box> */}
			</Container>
		</React.Fragment>
	);
}

export default Footer;