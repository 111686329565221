import React, { useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {Share} from 'react-native'
import axios from 'axios';
import Riddle from './Riddle';
import Results from './Results';
import Strikes from './Strikes';
import Alert from '@mui/material/Alert';
import Celebration from './Celebration';
axios.defaults.withCredentials = true;

function ifHyphen(str){
    return str.toLowerCase().split('-').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join('-');
}


function toTitleCase(str) {

    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const Game = (props) => {

	
	

    const { answers, countries } = props;
    const [strikes] = useState([])
    const [currentMonth, setCurrentMonth] = useState([])
	const [text, setText] = useState([])
    const [correctAnswers] = useState([])
    const [showGuess, setShowGuess] = useState([])
    const [confetti, setConfetti] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [finishMessage, setFinishMessage] = useState([])
    const [pointMessage, setPointMessage] = useState([])
    const [shareMessage, setShareMessage] = useState([])
    const [history, setHistory] = useState([])
    const [finished, setFinished] = useState('Not Finished')
    const [helpertext, setHelpertext] = useState([])
    const [percentile, setPercentile] = useState([])
    
    // const [percentileMessage, setPercentileMessage] = useState([])

    useEffect(() => {
        if (percentile.length!==0 && document.getElementById("share-button-id")) {
            console.log(percentile)
            document.getElementById("share-button-id").style.display = "inline-flex";
        };

     });
     
    const getPercentile =  async(month, userUniqueKey) => {
        const user_percentile =  await axios.get(`https://georiddle.azurewebsites.net/api/results/${month}/` + userUniqueKey + '/')            
        setPercentile(user_percentile.data[userUniqueKey])
    };

    function getMonth(){
        let monthInt = new Date().getMonth();
        switch (monthInt) {
            case 0:
                return('January')
            case 1:
                return('February')
            case 2:
                return('March')
            case 3:
                return('April')

            case 4:
                return('May')
            case 5:
                return('June')
            case 6:
                return('July')
            case 7:
                return('August')
            case 8:
                return('September')
            case 9:
                return('October')
            case 10:
                return('November')
            case 11:
                return('December')
            default:
                return('None')
        }
    }
    const [userUniqueKey] = useState(localStorage.getItem("unique_key"))
    function lost() {
        let month = getMonth()
        setCurrentMonth(month)
        setFinished('Lost')
        setHistory([])
        setShowGuess(false)
        let points = 1
        let greenSquare = `\uD83D\uDFE9`
        let yellowSquare = `\uD83D\uDFE8`
        let redSquare = `\uD83D\uDFE5`
        let blackSquare = `\u2B1B`
        let strikesMessage = ''
        let answerMessage = ''
        let correct = 0
        let trueStrikes = 0

        for (var i = 0; i < answers.answers.length; i++){

            if (history.includes(answers.answers[i].name)){
                answerMessage+=greenSquare
                correct+=1
            } 
            else{
                answerMessage+=blackSquare
            }
        }
        for (var j = 0; j < strikes.length; j++){
            if (strikes[j]==='Hint'){
                strikesMessage+=yellowSquare
            }
            else{
                trueStrikes += 1
                strikesMessage+=redSquare
            };
            }
        points = Math.round((5 * correct)/(answers.answers.length)) - trueStrikes
        let pointsString = 'points'
        if (points < 2){
            points = 1
            pointsString = 'point'
        }
            setPointMessage(`${points} ${pointsString}`)
        


        const scoringApis = async () => {
            if (document.getElementById("share-button-id")){
                console.log(document.getElementById("share-button-id"))
                document.getElementById("share-button-id").style.display = "none";
            }


            if(JSON.parse(localStorage.getItem("post_score_recent_day") !== JSON.stringify(answers.day))){
         
                localStorage.setItem("post_score_recent_day", JSON.stringify(answers.day));
                await axios.post(`https://georiddle.azurewebsites.net/api/results/${month}/` + userUniqueKey + '/', {
                    'points': points,
                    'day': answers.day,
                });
            }
            
            await getPercentile(month, userUniqueKey)

        }
        scoringApis();
            

        setFinishMessage(`Too bad! You got ${history.length - strikes.length} out of ${answers.answers.length} countries. `)
        setShareMessage(`GeoRiddle #${answers.day.toString()} (${points} ${pointsString}) \n${answerMessage}\uD83D\uDC4E\n${strikesMessage}`)
    }

    function won() {
        let month = getMonth()
        setCurrentMonth(month)
        setFinished('Won')
        setHistory([])
        setShowGuess(false)
        let greenSquare = `\uD83D\uDFE9`
        let yellowSquare = `\uD83D\uDFE8`
        let redSquare = `\uD83D\uDFE5`
        let blackSquare = `\u2B1B`
        let strikesMessage = ''

        let points = 8


        for (var i = 0; i < strikes.length; i++){
            if (strikes[i]==='Hint'){
                strikesMessage+=yellowSquare

            }
            else{
                strikesMessage+=redSquare
                points -=1


            };
            }

        if (strikes.length === 0){
            points += 2
            setFinishMessage(`Perfect game! 👌 `)      
        }
        else{
            setFinishMessage(`Nice work! You got all ${answers.answers.length} countries. `)
        }

        // sending points
        const scoringApis = async () => {
            if (document.getElementById("share-button-id")){
                document.getElementById("share-button-id").style.display = "none";
            }

            if(JSON.parse(localStorage.getItem("post_score_recent_day") !== JSON.stringify(answers.day))){
                localStorage.setItem("post_score_recent_day", JSON.stringify(answers.day));
                await axios.post(`https://georiddle.azurewebsites.net/api/results/${month}/` + userUniqueKey + '/', {
                    'won': true,
                    'points': points,
                    'day': answers.day
                });
            }
            await getPercentile(month, userUniqueKey)

            //setPercentileMessage(`You're in the top ${user_percentile.data[userUniqueKey]}% this month`)
        }
        scoringApis();  
        setPointMessage(`You scored ${points} points`)

        setShareMessage(`GeoRiddle #${answers.day.toString()}  (${points} points) \n${greenSquare.repeat(history.length - strikes.length)}${blackSquare.repeat(answers.answers.length-history.length+strikes.length)}\uD83C\uDF89\n${strikesMessage}`)
        setConfetti(true)
    }


    // Filling out answers so I can check history
    
    if (correctAnswers.length===0){
        for (var i = 0; i < answers.answers.length; i++){
            correctAnswers.push(answers.answers[i].name)
            }
    }
    
    // Filling out history
    // checking day and if its not today then not filling history and erasing it
    if(JSON.parse(localStorage.getItem("most_recent_day") !== JSON.stringify(answers.day))){
        localStorage.setItem("most_recent_day", JSON.stringify(answers.day));
        localStorage.setItem("guesses", JSON.stringify(''));
    } 
    else{
        if (!history.length > 0 && JSON.parse(localStorage.getItem("guesses"))){
            setHistory(JSON.parse(localStorage.getItem("guesses")))
        }
    }

    //filling out strikes
    if (strikes.length===0 && finished==='Not Finished'){
        for (var j = 0; j < history.length; j++){
            if (!correctAnswers.includes(history[j])){
                if (history[j] === 'Hint'){
                    strikes.push('Hint')
                }
                else{
                    strikes.push(history[j])
                }
            }

            if (strikes.length === 3){
                lost()
            }
            
        }
        if ((answers.answers.length + strikes.length - history.length) === 0){

            won();
        }

    }
    

    //this is the iphone/whatsapp share method
    const onShare = () => {

              try {
                //let currentMonth = getMonth();
                let trophy = ``
                if (percentile <10 && percentile > 0){
                    trophy = `\uD83E\uDD47`}
                if (percentile <20 && percentile >10){
                    trophy = `\uD83E\uDD48`}
                if (percentile <30 && percentile >20){
                    trophy = `\uD83E\uDD49`}
                let secondHalfMessage = `\n${currentMonth} Rank: Top ${percentile}% ${trophy}`
                if (percentile === 0){
                    secondHalfMessage = `\n${currentMonth} Rank: 1st Place \uD83C\uDFC6`
                }   


                let fullShareMessage = shareMessage + secondHalfMessage + `\nwww.georiddle.app`
                if (fullShareMessage){
                    document.getElementById("copiedAlert").style.display = "flex";
                    setHelpertext('Results Copied to Clipboard')
                    navigator.clipboard.writeText(fullShareMessage)
                }

                const result = Share.share({

                  message: fullShareMessage 
                },
                {
                    excludedActivityTypes:[
                        'com.apple.UIKit.activity.PostToWeibo',
                        'com.apple.UIKit.activity.Print',
                        'com.apple.UIKit.activity.AssignToContact',
                        'com.apple.UIKit.activity.SaveToCameraRoll',
                        'com.apple.UIKit.activity.AddToReadingList',
                        'com.apple.UIKit.activity.PostToFlickr',
                        'com.apple.UIKit.activity.PostToVimeo',
                        'com.apple.UIKit.activity.PostToTencentWeibo',
                        'com.apple.UIKit.activity.AirDrop',
                        'com.apple.UIKit.activity.OpenInIBooks',
                        'com.apple.U"IKit.activity.MarkupAsPDF',
                        'com.apple.reminders.RemindersEditorExtension',
                        'com.apple.mobilenotes.SharingExtension',
                        'com.apple.mobileslideshow.StreamShareService',
                        'com.linkedin.LinkedIn.ShareExtension',
                        'pinterest.ShareExtension',
                        'com.google.GooglePlus.ShareExtension',
                        'com.tumblr.tumblr.Share-With-Tumblr',
                    ]
                });
                if (result.action === Share.sharedAction) {
                  if (result.activityType) {
                    // shared with activity type of result.activityType
                  } else {
                    // shared
                  }
                } else if (result.action === Share.dismissedAction) {
                  // dismissed
                }
              } catch (error) {
               console.log(error.message)
              }
            };
    
    const onSuggestHandler = (text)=>{

        setText(text);
        setSuggestions([]);
    }
    
	const onChangeHandler = (text) => {

        //clears the react alert
        document.getElementById("duplicateAlert").style.display = "none";
        let matches = []
        let suggestionStart = 2
        //suggestions start after two letters on for the first guesses
        if (strikes.length>1){
            suggestionStart+=1
        }
        //suggestions start ater three letters on last guess
        if (text.length > suggestionStart) {
            matches = countries.filter(country => {
            const regex = new RegExp(`^${text}`, "gi");
            return country.name.match(regex)
        })
        }
		setSuggestions(matches.slice(0,5))
        setText(text)
        
	}

    const onHint = () => { 
        onChangeHandler('')
        strikes.push('Hint')
        history.push('Hint')
        
        localStorage.setItem("guesses", JSON.stringify(history));

        //This is for if You want to do more indvidual stats later
        // axios.post('https://georiddle.azurewebsites.net/api/guesses/' + answers.day +'/', {
        // // Not sure if this tbelongs here
        // 'country': 'Hint'
        //   });

        if (strikes.length === 3){
            lost()
        }
        return
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setSuggestions([]);
            
        let submittedText = ''
        submittedText = toTitleCase(text.trim())
        if (submittedText.includes('-')){
            submittedText = ifHyphen(submittedText)
        }
 
        if (history.includes(submittedText)){
            document.getElementById("duplicateAlert").style.display = "flex";
            setHelpertext('Already guessed. Try a new country!')
            return
        }

        //checking to see if the country is in the country list
        let countryValidation 
        countryValidation = countries.filter(country => {

            const regex = new RegExp(`^${submittedText}$`, "i");
            return (country.name.match(regex))
        })

        if (countryValidation.length===0){
            document.getElementById("duplicateAlert").style.display = "flex";
            setHelpertext('Enter a valid country')
            return
        }

        history.push(submittedText)
        
        //setitng info in the broswer storage
        localStorage.setItem("guesses", JSON.stringify(history));

        //another request that can be used for stats later

        // axios.post('https://georiddle.azurewebsites.net/api/guesses/' + answers.day +'/', {
        // // Not sure if this tbelongs here
        // 'country': submittedText
        //   });
    
        let isStrike = true
        if (correctAnswers.includes(submittedText)){

            isStrike = false
        }


        if (isStrike === true){
            strikes.push(submittedText)

            if  (strikes.length===3) {
                lost()
            }
            setText('')
            return
        }
    
        if ((answers.answers.length+strikes.length-history.length)===0 ){
            won();
    
        }
    
        setText('')
        return
    }


    


	if (!answers || answers.length === 0) return <p>Can not find any answers, sorry</p>;


	return (

		<React.Fragment>
        <Grid
        style={{
            
            padding: "15px",
            maxWidth: "400px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#0f172a",
            minHeight: "95vh",
        }}
        >

        <Grid
            style={{
            width: "100%",
            backgroundColor: "#0f172a",
            }}
        >
            {/* <Riddle answers={answers} countries={countries} strikes = {strikes} history = {history} finish = {finished}/> */}
            <Riddle answers={answers} finished = {finished} />
            <Results history = {history} answers = {answers}  finished = {finished} strikes = {strikes}/>
            { confetti ? <Celebration/> : null}
            <Strikes strikes = {strikes} finished = {finished} />
            { showGuess ? 
            
            <Grid
                style={{
                width: "100%",
                backgroundColor: "#0f172a",
                }}
                >
                    <Grid
                style={{
                marginBottom: "10px",
                width: "100%",
                backgroundColor: "#0f172a",
                }}
                >

                    {suggestions && suggestions.map((suggestion,i) =>
                    <Grid 
                    style={{
                        height: "34px",
                        backgroundColor: "#475569",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        
                    }}>
                        <Typography  
                            key={i} 
                            style={{ 
                                height: "34px",
                                border: "1px solid #fafafa",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#FFFFFF",
                                width: "100vw",
                                paddingTop: "4px",
                            }}

                            noWrap
                            onClick={()=>onSuggestHandler(suggestion.name)}>
                            {suggestion.name}
                        </Typography>
                        </Grid>
                            )}
                        </Grid>     
                        <form onSubmit={handleSubmit} >
                        <Grid 
                            style={{
                                height: "30px",
                                backgroundColor: "#475569",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <TextField
                            label="Guess a country..."
                            
                            InputProps = {{
                                style: { fontSize: "medium", color: '#FFFFFF',marginLeft: '3%'},
                            }}
                            InputLabelProps={{
                                style: { fontSize: "small", color: '#AAAAAA', marginLeft: '3%', shrink: 'true' },
                            }}
                            

                            onChange={e=> onChangeHandler(e.target.value)}
                            value={text}
                            style={{
                                border: "thin solid #fafafa",
                                color: "#fafafa",
                                backgroundColor: "#475569",
                                width: "100vw",
                            }}
                            />
                        </Grid>
                        <Grid container spacing={12} >
                        <Grid xs={8}>
                                <Button 
                                type="submit"
                                variant="contained"
                                style={{
                                    fontSize: "large",
                                    alignItems: "center",
                                    border: "thin solid #fafafa",
                                    fontWeight: "bold",
                                    backgroundColor: "#6ca965",
                                    color: '#FFFFFF',
                                    width: "100%",
                                    height: "6vh",
                                    padding: "5px",
                                    marginTop: "15px"
                                }}
                                disableElevation>
                                    Submit &#x1F30D;
                                </Button>
                            </Grid>       
                            <Grid  xs={1}  
                            style={{
                                marginLeft: "-5px",
                                marginRight: "-5px"}}></Grid>  
                        <Grid xs={3}>
                                { (strikes.length !==2) ? 
                                <Button 
                                id = "hintButton"
                                onClick={()=>onHint()}
                                variant="contained"
                                style={{
                                    fontWeight: "900",
                                    alignItems: "center",
                                    border: "thin solid #fafafa",
                                    backgroundColor: "#c4b24d",
                                    color: '#FFFFFF',
                                    width: "110%",
                                    height: "6vh",
                                    padding: "5px",
                                    marginTop: "15px",
                                    marginRight: "0px",
                                    }}
                                disableElevation>
                                    Hint 	&#128270;
                                </Button>
                                
                                :
                                
                                <Button 
                                id = "hintButton"
                                onClick={()=>onHint()}
                                variant="contained"
                                style={{
                                    fontWeight: "700",
                                    alignItems: "center",
                                    border: "thin solid #fafafa",
                                    backgroundColor: "#FF0000",
                                    color: '#FFFFFF',
                                    width: "100%",
                                    height: "6vh",
                                    padding: "4px",
                                    marginTop: "15px",
                                    marginRight: "5px",
                                    }}
                                disableElevation>
                                GIVE UP &#x1F3F3;&#xFE0F;
                                </Button>
                                }
                            </Grid>   

               

                        </Grid>
                    </form>
                </Grid>
                :
                    <Grid >
                       <Typography
                        style={{
                            color: "white",
                            fontSize: "large"}}
                            >
                        {finishMessage}
                        </Typography>
                        <Alert id = "copiedAlert" variant="filled" severity="success" style={{display: "none", marginTop: "8%"}}>
                        Copied to Clipboard
                        </Alert>
                        <Button 
                        id = "share-button-id"
                        onClick={()=>onShare()}
                        variant="contained"
                        style={{
                            display: "none",
                            fontWeight: "900",
                            border: "thin solid #fafafa",
                            backgroundColor: "#475569",
                            color: '#FFFFFF',
                            width: "100%",
                            height: "5vh",
                            padding: "5px",
                            marginTop: "10px",
                            marginBottom: "15px"
                            }}
                        disableElevation>
                            Share to see monthly ranking &#x2708;&#xFE0F;
                        </Button>
                        <Typography
                        style={{
                            color: "white",
                            fontSize: "large"}}
                            >
                       {pointMessage}
                        </Typography>
                    </Grid>    }
                    <Alert id = "duplicateAlert" variant="filled" severity="error" style={{display: "none", marginTop: "8%"}}>
                    {helpertext}
                    </Alert>
                </Grid>  
            </Grid>
		</React.Fragment>
	);
};

export default Game;