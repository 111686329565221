import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import Game from './components/Game';
import CountriesLoadingComponent from './components/CountriesLoading';
import axios from 'axios'
axios.defaults.withCredentials = true;

function generateUUID()
{
var d = new Date().getTime();
  
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c)
  {
    var r = (d + Math.random()*16) % 16 | 0;
    d = Math.floor(d/16);
    return (c==='x' ? r : ((r&0x3)|0x8)).toString(16);
  });

return uuid;
}

function App() {
	// const AnswersLoading = AnswersLoadingComponent(Riddle);
	const CountriesLoading = CountriesLoadingComponent(Game)
	// const RiddleLoading = RiddleLoadingComponent(Riddle)
	// should be march 9
	const startDate = new Date('March 9, 2023 23:00:00');

	//////

	const currentDate = new Date();
	///// 
	const [riddleDay] = useState(Math.ceil((currentDate-startDate)/86400000));



	// /setRiddleDay(Math.ceil((currentDate-startDate)/86400000)) // find the riddle day by subtracting the number of days, dividing by milliseconds and rounding up
	
	/// for testing only


	const [appState, setAppState] = useState({
		
		loading: true,
		countries: null,
		answers: null,

	});

	
	//set uuid if not already set
	if(!localStorage.getItem("unique_key")){
		localStorage.setItem("unique_key", generateUUID())
	}


	useEffect(() => {
		let updateRelease = false
		//set this to reload on certain days
		if (riddleDay > 52 && riddleDay < 58 ){
			updateRelease = true
		}

		const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
		if(reloadCount < 2 && updateRelease ===true) {
			sessionStorage.setItem('reloadCount', String(reloadCount + 1));
			window.location.reload();

		} else {
			sessionStorage.removeItem('reloadCount');
		}
		}, [riddleDay]);

	
	
	useEffect(() => {
		setAppState({ loading: true });
		
		const loadCountries = async () => {
			console.log(riddleDay)
			// Use modulo to cycle tempRiddleDay between 1 and 147
			let tempRiddleDay = ((riddleDay - 1) % 147) + 1;


			const countryData = await axios.get('https://georiddle.azurewebsites.net/api/countries/')
			// const guessData = await axios.get('https://georiddle.azurewebsites.net/api/guesses/' + riddleDay + '/')
			const answerData = await axios.get('https://georiddle.azurewebsites.net/api/riddles/' + tempRiddleDay + '/')
			setAppState({ loading: false, answers: answerData.data, countries: countryData.data});
		}
		loadCountries();


	},[riddleDay, setAppState]);


	return (

		<div className="App"         
			style={{
				justifyContent: "center",
				backgroundColor: "#0f172a",
				width: "100%",
				display: "flex",
				minHeight: "95vh",
			}}>
			<HelmetProvider>
  				<div>
					<Helmet>
						
						<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
						<title>GeoRiddle</title>
					</Helmet>
				</div>
			</HelmetProvider>
			
			{/* <RiddleLoading isLoading={appState.loadingAnswers} answers={appState.answers}/> */}
			{/* <AnswersLoading isLoading={appState.loadingCountries} answers={appState.answers} countries={appState.countries}/> */}
			<CountriesLoading isLoading={appState.loading} answers={appState.answers} countries={appState.countries}/>
		</div>
		
	);
}
export default App;