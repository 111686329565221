import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid'


const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid #FFFFFF `,
        backgroundColor: "#475569", 
        color: ' #FFFFFF',
        textAlign:"center",
        fontWeight: '700',
        letterSpacing: '.075em',
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
}));


export default function Header() {
    
    const [contextMenu, setContextMenu] = useState(false)

    const howTo = () => { 
        setContextMenu(true)
        document.getElementById("contextMenu").style.display = "flex";
        document.getElementById("topHeader").innerHTML = "How to Play";
        return
    }
    
    const handleClose = () => {
        setContextMenu(false)
        document.getElementById("contextMenu").style.display = "none";
        document.getElementById("topHeader").innerHTML = "GeoRiddle";
    };
    
    const classes = useStyles();

    return (
    <React.Fragment>
        <CssBaseline />

        <AppBar
            
            position="static"
            color="default"
            elevation={0}
            className={classes.appBar}
        >
            <Toolbar
                    style={{
                        maxWidth: "400vw",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                <Box
                    component="img"
                    alt="logo"
                    src="https://www.georiddle.app/favicon.ico"
                />
                <Typography id = "topHeader" variant="h4" color="inherit" noWrap style={{
                    paddingLeft:"3vw"
                }}
                >GeoRiddle 
                </Typography>
                <Box style={{paddingLeft: "20vw"}}>
                    { !contextMenu ? 
                    <QuestionMarkIcon 
                        onClick={()=> howTo()}
                    ></QuestionMarkIcon> : 
                    <CloseIcon          
                        onClick={()=> handleClose()}>
                    </CloseIcon>
                    }
                </Box>
            </Toolbar>
        </AppBar>
        
        <Grid   id = "contextMenu" 
                            style={{
                                maxWidth: "400vw",
                                width: "100%",
                                display: "none",
                                justifyContent: "none",
                                backgroundColor: "#475569"}}
                            >
            <Typography  style={{ padding: "4%", color: "#fafafa" }}>
                <h3 style={{textDecoration: "underline"}}>Game Play</h3>
                <li>Try to name all the UN member countries that fit the riddle</li><br></br>
                <li>If you guess incorrectly, you get a strike -- 3 strikes and you're out!</li><br></br>
                <li>However, a strike will reveal a hint. First hint will shows the position of each letter. Second hint will reveal the vowels. </li><br></br>
                <li>If you can't think of a country, click &nbsp; HINT&#x1F50E; &nbsp; -- you will be penalized with a strike </li><br></br>
                <li>The answers are sorted by the geographic feature in the riddle, such as size, population, closest to the equator, etc.</li><br></br>
                <li>If there are no geographic features in the riddle, the results are sorted alphabetically</li><br></br>
                <li>Click &nbsp;SHARE&#x2708;&#xFE0F; &nbsp;afterwards to send results to your friends and see your monthly rank </li><br></br>
                <li>Come back every day for a new riddle!</li>
                <br></br> 
                <h3 style={{textDecoration: "underline"}}>Countries</h3>
                <li>There are 197 countries in the game, representing the 193 UN Nations plus 4 generally agreed upon countries</li><br></br>
                <li>The Country Dataset can be found <a style={{backgroundColor: "white"}} href = 'https://github.com/freemanwsmith3/georiddle/blob/master/GeoRiddle_Country_Set.csv'>HERE</a>. Anyone is welcome to use it.</li><br></br>
                <li>If a country is most commonly referred to by another language, even by English speakers, we use that spelling (e.g. El Salvador, Cabo Verde).</li><br></br>
                <li>If it is ambigous between English and another language, we default to the English version of countries (e.g. we use Ivory Coast instead of Cote d'Ivoire).</li><br></br>
                <li>Special characters will be taken out of the countries to make it more consistent (e.g. we use Sao Tome and Principe instead of São Tomé and Príncipe). </li><br></br>
                <li>There is absolutely no politiical motivation behind the countries selected, simply an effort to be consistent and fun.</li><br></br>
                <br></br> 
                <h3 style={{textDecoration: "underline"}}>Scoring</h3>
                <li>The number of correct answers normalized out of 5 points (e.g. 6/8 = 3.75/5 so it rounds to 4 points)</li><br></br>
                <li>Minus 1 for every strike (no penalty for hints)</li><br></br>
                <li>3 point bonus if you win</li><br></br>
                <li>2 point bonus for a perfect game</li><br></br>
                <li>Minimum 1 point for playing</li><br></br>
                <li>Examples:</li><br></br>
                <p style={{border: "solid", padding: "2%", justifyContent: "center"}}> <span style={{textDecoration: "underline"}}>8 out of 8 correct with no Strikes or Hints</span><br></br><br></br>
                <span >5 Answer Points (8/8 = 5/5) + 3 Win Bonus + 2 Perfect Bonus = <span style={{textDecoration: "underline"}}>10 Points</span></span></p><br></br>
                <p style={{border: "solid", padding: "2%"}}> <span style={{textDecoration: "underline"}}>3 out of 3 correct with 1 Strike and 1 Hint</span><br></br><br></br>
                <span >5 Answer Points (3/3 = 5/5) - 1 Strike + 3 Win Bonus = <span style={{textDecoration: "underline"}}>7 Points</span></span></p><br></br>
                <p style={{border: "solid", padding: "2%"}}> <span style={{textDecoration: "underline"}}>2 out of 3 correct with 1 Strike and 2 Hints</span><br></br><br></br>
                <span >3 Answer Points (2/3 rounds to 3/5) - 1 Strike = <span style={{textDecoration: "underline"}}>2 Points</span></span></p><br></br>
                <p style={{border: "solid", padding: "2%"}}> <span style={{textDecoration: "underline"}}>3 out of 8 correct with 2 Strikes and 1 Hint</span><br></br><br></br>
                <span >2 Answer Points (3/8 rounds to 2/5) - 2 Strike = 0 Points, but minimum is <span style={{textDecoration: "underline"}}>1 Point</span></span></p><br></br>         
                
			<span style={{fontSize: "small", display: "flex", justifyContent: "center"}}>
            {'Copyright © '}
				GeoRiddle 
			{' '}
			2023
			{' '}
			<br></br> &nbsp; georiddleapp@gmail.com</span>
			</Typography>
        </Grid>
    </React.Fragment>
    );
}
