import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const Results = (props) => {

    let ads = true
	const {  strikes, finished } = props;
    if (finished === 'Not Finished') {
        ads = false}

    return (
		<React.Fragment>                    
        <Grid
            style={{
            width: "100%",
            marginBottom: "vw",
            backgroundColor: "#0f172a",
            }}
        >
            { ads ? 
            <a href="https://www.letsgogeography.com?aff=freemanwsmith3&p=507004"><img sx = {{marginTop : '10px'}} src="https://www.letsgogeography.com/wp-content/uploads/2021/04/ExploreTheWorld.jpg" alt="Let's go Geography" width="50%" height="50%" /></a>
            :
            <List 
            style={{ width: "100%"}}>
                {strikes.map((ans, index) => {   
                    return strikes[index]==='Hint' ? ( 
                        <Grid 
                        style={{
                            marginTop: "5px",
                            height: "30px",
                            backgroundColor: "#c8b653",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        <Typography >
                            <li style={{color: '#FFFFFF'}} key={index}>Strike {index+1} : {strikes[index]}</li>
                        </Typography>
                    </Grid>
                    ) : 
                    <Grid 
                    style={{
                        marginTop: "5px",
                        height: "30px",
                        backgroundColor: "#FF0000",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <Typography >
                            <li style={{color: '#FFFFFF'}} key={index}>Strike {index+1} : {strikes[index]}</li>
                        </Typography>
                    </Grid>
                    })}
                </List>  }
            </Grid>
        </React.Fragment>
    );
};

export default Results